import {
  LngLatBounds,
  SearchSession,
  GeocodingCore,
  GeocodingOptions,
  GeocodingFeature,
  GeocodingResponse
} from '@mapbox/search-js-core';
import mapboxgl from 'mapbox-gl';
import subtag from 'subtag';

import { MapboxSearchListbox } from './MapboxSearchListbox';
import { HTMLScopedElement } from './HTMLScopedElement';

import { tryParseJSON } from '../utils';
import { bboxViewport, FLY_TO_SPEED, getMaxZoom } from '../utils/map';

import { Theme, getIcon, getThemeCSS } from '../theme';
import { MapboxHTMLEvent } from '../MapboxHTMLEvent';
import { GEOCODER_TEMPLATE } from '../constants';

import style from '../style.css';
import { PopoverOptions } from '../utils/popover';
import { createAriaLiveElement } from '../utils/aria';
import { bindElements } from '../utils/dom';
import { SEARCH_SERVICE } from '../utils/services';
import localization from '../utils/localization';

/**
 * Proximity is designed for local scale. If the user is looking at the whole world,
 * it doesn't make sense to factor in the arbitrary center of the map.
 */
const MAX_ZOOM = 9;

type Binding = {
  /**
   * Wrapper around the entire Geocoder component.
   */
  Geocoder: HTMLElement;
  /**
   * Container for search icon preceding input
   */
  SearchIcon: HTMLDivElement;
  /**
   * The input element accepting search text
   */
  Input: HTMLInputElement;
  /**
   * Button element used to clear the input element of text
   */
  ClearBtn: HTMLButtonElement;
  /**
   * Animated loading icon triggered by a keystroke
   */
  LoadingIcon: HTMLDivElement;
};

export type MapboxSearchListboxSearchType =
  MapboxSearchListbox<GeocodingFeature>;

type SearchEventTypes = {
  /**
   * Fired when the user is typing and is provided a list of suggestions.
   *
   * The underlying response from {@link GeocodingCore} is passed as the event's detail.
   *
   * @event suggest
   * @instance
   * @memberof MapboxGeocoder
   * @type {GeocodingResponse}
   * @example
   * ```typescript
   * search.addEventListener('suggest', (event) => {
   *   const suggestions = event.detail.suggestions;
   *   // ...
   * });
   * ```
   */
  suggest: MapboxHTMLEvent<GeocodingResponse>;
  /**
   * Fired when {@link GeocodingCore} has errored providing a list of suggestions.
   *
   * The underlying error is passed as the event's detail.
   *
   * @event suggesterror
   * @instance
   * @memberof MapboxGeocoder
   * @type {Error}
   * @example
   * ```typescript
   * search.addEventListener('suggesterror', (event) => {
   *   const error = event.detail;
   *   // ...
   * });
   * ```
   */
  suggesterror: MapboxHTMLEvent<Error>;
  /**
   * Fired when the user has selected a suggestion.
   *
   * The underlying response from {@link GeocodingCore} is passed as the event's detail.
   *
   * @event retrieve
   * @instance
   * @memberof MapboxGeocoder
   * @type {GeocodingFeature}
   * @example
   * ```typescript
   * search.addEventListener('retrieve', (event) => {
   *   const feature = event.detail;
   *   // ...
   * });
   * ```
   */
  retrieve: MapboxHTMLEvent<GeocodingFeature>;
  /**
   * Fired when the user has changed the `<input>` text.
   *
   * The new input value is passed as the event's detail.
   *
   * @event input
   * @instance
   * @memberof MapboxGeocoder
   * @type {string}
   * @example
   * ```typescript
   * search.addEventListener('input', (event) => {
   *   if (e.target !== e.currentTarget) return;
   *   const searchText = event.detail;
   *   // ...
   * });
   * ```
   */
  input: MapboxHTMLEvent<unknown>;
};

/**
 * `MapboxGeocoder`, also available as the element `<mapbox-geocoder>`,
 * is an element that lets you search for addresses and places using
 * the [Mapbox Geocoding API](https://docs.mapbox.com/api/search/geocoding-v6/).
 *
 * It can control a [Mapbox GL JS](https://docs.mapbox.com/mapbox-gl-js/guides/) map
 * to zoom to the selected result.
 *
 * Additionally, `MapboxGeocoder` implements the [IControl](https://www.mapbox.com/mapbox-gl-js/api/markers/#icontrol)
 * interface.
 *
 * To use this element, you must have a [Mapbox access token](https://www.mapbox.com/help/create-api-access-token/).
 *
 * @class MapboxGeocoder
 * @example
 * ```typescript
 * const search = new MapboxGeocoder();
 * search.accessToken = '<your access token here>';
 * map.addControl(search);
 * ```
 * @example
 * <mapbox-geocoder
 *   access-token="<your access token here>"
 *   proximity="0,0"
 * >
 * </mapbox-geocoder>
 */
export class MapboxGeocoder
  extends HTMLScopedElement<SearchEventTypes>
  implements mapboxgl.IControl
{
  /**
   * This is read by the Web Components API to affect the
   * {@link MapboxGeocoder#attributeChangedCallback} below.
   *
   * All of these are passthroughs to the underlying {@link MapboxSearchListbox}.
   *
   * @ignore
   */
  static observedAttributes: string[] = [
    // Access token.
    'access-token',
    // Theming.
    'theme',
    'popover-options',
    'placeholder',
    // Underlying Geocoding API options.
    'autocomplete',
    'language',
    'country',
    'bbox',
    'limit',
    'proximity',
    'types',
    'worldview',
    'permanent'
  ];

  #binding: Binding;

  #search = new GeocodingCore({});
  #session = new SearchSession<
    GeocodingOptions,
    GeocodingFeature,
    GeocodingResponse,
    GeocodingFeature
  >(this.#search);

  /**
   * The [Mapbox access token](https://docs.mapbox.com/help/glossary/access-token/) to use for all requests.
   *
   * @name accessToken
   * @instance
   * @memberof MapboxGeocoder
   * @example
   * ```typescript
   * search.accessToken = 'pk.my-mapbox-access-token';
   * ```
   */
  get accessToken(): string {
    return this.#search.accessToken;
  }
  set accessToken(newToken: string) {
    this.#search.accessToken = newToken;
  }

  /**
   * The value of the input element.
   *
   * @name value
   * @instance
   * @memberof MapboxGeocoder
   * @example
   * ```typescript
   * console.log(search.value);
   * ```
   */
  get value(): string {
    return this.#input.value;
  }
  set value(newValue: string) {
    this.#input.value = newValue;
  }

  #map: mapboxgl.Map | null = null;

  #input: HTMLInputElement;
  #listbox: MapboxSearchListboxSearchType = new MapboxSearchListbox();

  /**
   * The `<input>` element wrapped by the autofill component.
   *
   * @name input
   * @instance
   * @memberof MapboxGeocoder
   * @type {HTMLInputElement}
   */
  get input(): HTMLInputElement {
    return this.#input;
  }

  protected override get template(): HTMLTemplateElement {
    return GEOCODER_TEMPLATE;
  }

  protected override get templateStyle(): string {
    return style;
  }

  protected override get templateUserStyle(): string {
    return getThemeCSS('.Geocoder', this.#listbox.theme);
  }

  /**
   * Options to pass to the underlying {@link GeocodingCore} interface.
   *
   * @name options
   * @instance
   * @memberof MapboxGeocoder
   * @type {GeocodingOptions}
   * @example
   * ```typescript
   * search.options = {
   *  language: 'en',
   *  country: 'US',
   * };
   * ```
   */
  options: Partial<GeocodingOptions> = {};

  /**
   * The {@link Theme} to use for styling the suggestion box and geocoder input box.
   *
   * @name theme
   * @instance
   * @memberof MapboxGeocoder
   * @type {Theme}
   * @example
   * ```typescript
   * search.theme = {
   *   variables: {
   *     colorPrimary: 'myBrandRed'
   *   },
   *   cssText: ".Input:active { opacity: 0.9; }"
   * };
   * ```
   */
  get theme(): Theme {
    return this.#listbox.theme;
  }
  set theme(theme: Theme) {
    this.#listbox.theme = theme;

    if (!this.#binding || !theme) {
      return;
    }

    this.updateTemplateUserStyle(getThemeCSS('.Geocoder', theme));
    this.#listbox.updatePopover();

    const { SearchIcon } = this.#binding;
    SearchIcon.innerHTML = getIcon('search', theme);
  }

  /**
   * The {@link PopoverOptions} to define popover positioning.
   *
   * @name popoverOptions
   * @instance
   * @memberof MapboxGeocoder
   * @type {PopoverOptions}
   * @example
   * ```typescript
   * search.popoverOptions = {
   *   placement: 'top-start',
   *   flip: true,
   *   offset: 5
   * };
   * ```
   */
  get popoverOptions(): Partial<PopoverOptions> {
    return this.#listbox.popoverOptions;
  }
  set popoverOptions(newOptions: Partial<PopoverOptions>) {
    this.#listbox.popoverOptions = newOptions;
  }

  #getDefaultPlaceholder(): string {
    if (this.options.language) {
      const firstLanguage = this.options.language.split(',')[0];
      const language = subtag.language(firstLanguage);
      const localizedValue = localization.placeholder[language];
      if (localizedValue) return localizedValue;
    }
    return 'Search';
  }

  #placeholder: string;

  /**
   * The input element's placeholder text. The default value may be localized if {@link GeocodingOptions#language} is set.
   *
   * @name placeholder
   * @instance
   * @memberof MapboxGeocoder
   * @type {string}
   */
  get placeholder(): string {
    return this.#placeholder || this.#getDefaultPlaceholder();
  }

  set placeholder(text: string) {
    this.#placeholder = text;
    if (this.#input) {
      this.#input.placeholder = this.placeholder;
      this.#input.setAttribute('aria-label', this.placeholder);
    }
  }

  #handleSuggest = (result: GeocodingResponse): void => {
    this.#setActionIcons();

    this.#listbox.handleSuggest(result?.features || null);
    // Manually bubble up the event.
    this.dispatchEvent(new MapboxHTMLEvent('suggest', result));
  };

  #handleSuggestError = (error: Error): void => {
    this.#setActionIcons();

    this.#listbox.handleError();
    // Manually bubble up the event.
    this.dispatchEvent(new MapboxHTMLEvent('suggesterror', error));
  };

  #handleRetrieve = (result: GeocodingFeature): void => {
    this.#setActionIcons();

    // Manually bubble up the event.
    this.dispatchEvent(new MapboxHTMLEvent('retrieve', result));

    const feature = result;
    if (!feature) {
      return;
    }

    // Set value of the input.
    this.#input.value = feature.properties.full_address;

    const map = this.#map;
    if (!map) {
      return;
    }

    const placeType = feature.properties.feature_type;

    const bounds = feature.properties.bbox;
    if (bounds) {
      map.flyTo(bboxViewport(map, LngLatBounds.convert(bounds).toFlatArray()));
    } else {
      const center = feature.geometry.coordinates as mapboxgl.LngLatLike;
      const zoom = getMaxZoom(placeType);

      map.flyTo({
        center,
        zoom,
        speed: FLY_TO_SPEED
      });
    }

    // Add marker to map
    if (this.marker && this.mapboxgl) {
      this.#handleMarker(feature);
    }
  };

  #mapMarker: mapboxgl.Marker;

  /**
   * Handle the removal of a feature marker
   */
  #removeMarker = (): void => {
    if (this.#mapMarker) {
      this.#mapMarker.remove();
      this.#mapMarker = null;
    }
  };

  /**
   * Handle the placement of a marker for the selected feature
   */
  #handleMarker = (feature: GeocodingFeature | null): void => {
    // clean up any old marker that might be present
    if (!this.#map) {
      return;
    }
    this.#removeMarker();

    if (!feature) return;

    const defaultMarkerOptions = {
      color: '#4668F2'
    };
    const markerOptions = {
      ...defaultMarkerOptions,
      ...(typeof this.marker === 'object' && this.marker)
    };
    this.#mapMarker = new this.mapboxgl.Marker(markerOptions);
    if (
      feature.geometry &&
      feature.geometry.type &&
      feature.geometry.type === 'Point' &&
      feature.geometry.coordinates
    ) {
      this.#mapMarker
        .setLngLat(feature.geometry.coordinates as mapboxgl.LngLatLike)
        .addTo(this.#map);
    }
  };

  /**
   * A callback providing the opportunity to validate and/or manipulate the input text before it triggers a search, for example by using a regular expression.
   * If a truthy string value is returned, it will be passed into the underlying search API. If `null`, `undefined` or empty string is returned, no search request will be performed.
   *
   * @name interceptSearch
   * @instance
   * @memberof MapboxGeocoder
   * @example
   * Enable search only when the input value length is more than 3 characters.
   * ```typescript
   * search.interceptSearch = (val) => val?.length > 3 ? val : null;
   * ```
   */
  interceptSearch: (val: string) => string = null;

  #onHandleInput = (e: MapboxHTMLEvent<string>): void => {
    // Manually bubble up the event.
    this.dispatchEvent(e.clone());

    const inputText = e.detail;

    // Clear text, suggestions, markers, etc. if empty string
    if (!inputText) {
      this.#handleClear();
      return;
    }

    const alteredText = this.interceptSearch && this.interceptSearch(inputText);

    const searchText = this.interceptSearch ? alteredText : inputText;

    if (this.interceptSearch && !alteredText) {
      this.#listbox.hideResults();
      return;
    }

    this.#session.suggest(searchText, this.options);

    this.#setActionIcons(true);
  };

  #onHandleSelect = (e: MapboxHTMLEvent<GeocodingFeature>): void => {
    const suggestion = e.detail;
    this.#session.retrieve(suggestion, this.options);

    this.#setActionIcons(true);
  };

  #onHandleBlur = (): void => {
    // Abort any in-progress operations.
    this.#session.abort();
  };

  #setActionIcons = (loading = false): void => {
    if (loading) {
      this.#binding.ClearBtn.style.display = 'none';
      this.#binding.LoadingIcon.style.display = 'block';
    } else {
      this.#binding.LoadingIcon.style.display = 'none';
      this.#binding.ClearBtn.style.display = this.value ? 'block' : 'none';
    }
  };

  #handleClear = (): void => {
    this.value = '';
    this.#setActionIcons();
    this.#handleMarker(null);
    this.#listbox.handleSuggest(null);
  };

  /** @section {Map settings} */

  /**
   * A [mapbox-gl](https://github.com/mapbox/mapbox-gl-js) instance to use when creating [Markers](https://docs.mapbox.com/mapbox-gl-js/api/#marker). Required if {@link MapboxGeocoder#marker} is `true`.
   *
   * @name mapboxgl
   * @instance
   * @memberof MapboxGeocoder
   */
  mapboxgl: typeof mapboxgl;

  /**
   * If `true`, a [Marker](https://docs.mapbox.com/mapbox-gl-js/api/#marker) will be added to the map at the location of the user-selected result using a default set of Marker options.  If the value is an object, the marker will be constructed using these options. If `false`, no marker will be added to the map. Requires that {@link MapboxGeocoder#mapboxgl} also be set.
   *
   * @name marker
   * @instance
   * @memberof MapboxGeocoder
   * @type {boolean | mapboxgl.MarkerOptions}
   * @example
   * ```typescript
   * search.marker = {
   *   color: 'red',
   *   draggable: true
   * };
   * ```
   */
  marker: boolean | mapboxgl.MarkerOptions = true;

  override connectedCallback(): void {
    super.connectedCallback();

    this.#binding = bindElements<Binding>(this, {
      Geocoder: '.Geocoder',
      SearchIcon: '.SearchIcon',
      Input: '.Input',
      ClearBtn: '.ClearBtn',
      LoadingIcon: '.LoadingIcon'
    });

    // Initialize theme if not set before connectedCallback
    this.theme = { ...this.theme };

    const { Input, ClearBtn } = this.#binding;

    this.#input = Input;
    this.#listbox.input = Input;
    this.#listbox.searchService = SEARCH_SERVICE.Geocoding;

    this.#listbox.addEventListener('input', this.#onHandleInput);
    this.#listbox.addEventListener('select', this.#onHandleSelect);
    this.#listbox.addEventListener('blur', this.#onHandleBlur);

    this.#session.addEventListener('suggest', this.#handleSuggest);
    this.#session.addEventListener('suggesterror', this.#handleSuggestError);
    this.#session.addEventListener('retrieve', this.#handleRetrieve);

    ClearBtn.addEventListener('click', this.#handleClear);

    this.placeholder = this.#placeholder;

    document.body.appendChild(this.#listbox);

    if (Input) {
      // Remove any existing aria-live element that may be left over from, e.g., cloning the node
      if (Input.previousElementSibling.hasAttribute('aria-live')) {
        Input.previousElementSibling.remove();
      }
      Input.insertAdjacentElement(
        'beforebegin',
        createAriaLiveElement(this.#listbox.dataSeed)
      );
    }
  }

  disconnectedCallback(): void {
    this.#listbox.remove();
    this.#listbox.input = null;

    this.#listbox.removeEventListener('input', this.#onHandleInput);
    this.#listbox.removeEventListener('select', this.#onHandleSelect);
    this.#listbox.removeEventListener('blur', this.#onHandleBlur);

    this.#session.removeEventListener('suggest', this.#handleSuggest);
    this.#session.removeEventListener('suggesterror', this.#handleSuggestError);
    this.#session.removeEventListener('retrieve', this.#handleRetrieve);
  }

  attributeChangedCallback(
    name: string,
    oldValue: string,
    newValue: string
  ): void {
    if (name === 'access-token') {
      this.#search.accessToken = newValue;
      return;
    }

    if (name === 'theme') {
      this.theme = tryParseJSON(newValue);
      return;
    }

    if (name === 'popover-options') {
      this.popoverOptions = tryParseJSON(newValue);
      return;
    }

    if (name === 'placeholder') {
      this.placeholder = newValue;
      return;
    }

    // Convert to the proper name for options.
    // Example: eta-type => eta_type
    const optionName = name.split('-').join('_');

    if (!newValue) {
      delete this.options[optionName];
    }

    // Otherwise, assume it's a Geocoding API option.
    this.options[optionName] = newValue;

    if (optionName === 'language') {
      this.placeholder = this.#placeholder;
    }
  }

  /** @section {Methods} */

  /**
   * Focuses the input element.
   */
  focus(): void {
    this.#listbox.focus();
  }

  /**
   * Sets the input text and triggers a search programmatically
   */
  search(text: string): void {
    this.value = text;
    this.#onHandleInput(new MapboxHTMLEvent('input', text));
  }

  #handleMoveEnd = (): void => {
    const map = this.#map;
    const options = { ...this.options };

    if (map.getZoom() <= MAX_ZOOM) {
      delete options.proximity;
      this.options = options;

      return;
    }

    const center = map.getCenter();
    this.options = {
      ...options,
      proximity: center
    };
  };

  /** @section {Map binding} */

  /**
   * Connects the Geocoder to a [Map](https://docs.mapbox.com/mapbox-gl-js/api/#map),
   * which handles both setting proximity and zoom after a suggestion click.
   *
   * @example
   * ```typescript
   * const search = new MapboxGeocoder();
   * search.bindMap(map);
   * ```
   */
  bindMap(map: mapboxgl.Map): void {
    if (this.#map) {
      this.#map.off('moveend', this.#handleMoveEnd);
    }

    if (map) {
      map.on('moveend', this.#handleMoveEnd);
    }

    this.#map = map;
  }

  /**
   * Unbinds the Geocoder from a [Map](https://docs.mapbox.com/mapbox-gl-js/api/#map).
   */
  unbindMap(): void {
    this.bindMap(null);
  }

  // IControl interface.

  // eslint-disable-next-line custom-elements/no-method-prefixed-with-on
  onAdd(map: mapboxgl.Map): HTMLElement {
    this.bindMap(map);
    this.remove();

    const container = document.createElement('div');
    container.className = 'mapboxgl-ctrl';
    container.style.width = '300px';
    container.appendChild(this);

    return container;
  }

  // eslint-disable-next-line custom-elements/no-method-prefixed-with-on
  onRemove(): void {
    this.remove();
    this.unbindMap();
    this.#removeMarker();
  }

  getDefaultPosition(): string {
    return 'top-right';
  }
}

declare global {
  interface Window {
    MapboxGeocoder: typeof MapboxGeocoder;
  }
}

window.MapboxGeocoder = MapboxGeocoder;

if (!window.customElements.get('mapbox-geocoder')) {
  customElements.define('mapbox-geocoder', MapboxGeocoder);
}
