/**
 * Localized values for the placeholder string
 */
const placeholder = {
  //  list drawn from https://docs.mapbox.com/api/search/#language-coverage
  de: 'Suche', //  german
  it: 'Ricerca', // italian
  en: 'Search', //  english
  nl: 'Zoeken', // dutch
  fr: 'Chercher', // french
  ca: 'Cerca', // catalan
  he: 'לחפש', // hebrew
  ja: 'サーチ', // japanese
  lv: 'Meklēt', // latvian
  pt: 'Procurar', // portuguese
  sr: 'Претрага', // serbian
  zh: '搜索', // chinese-simplified
  cs: 'Vyhledávání', // czech
  hu: 'Keresés', // hungarian
  ka: 'ძიება', //  georgian
  nb: 'Søke', // norwegian
  sk: 'Vyhľadávanie', // slovak
  th: 'ค้นหา', // thai
  fi: 'Hae', // finnish
  is: 'Leita', // icelandic
  ko: '수색', // korean
  pl: 'Szukaj', // polish
  sl: 'Iskanje', // slovenian
  fa: 'جستجو', // persian(aka farsi)
  ru: 'Поиск' // russian
};

export default { placeholder };
